import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { get } from "../../util/functions"
import Button from "../../shared/Button/Button"
import color from "../../style/color"

const BlogSidebar = ({}) => {
  const data = useStaticQuery(graphql`
    query fourBlogPosts {
      allNodeBlog: allNodeWhitesBlog {
        edges {
          node {
            field_preview_heading
            fields {
              slug
            }
            field_url
            field_preview_text
            field_preview_image {
              alt
            }
            relationships {
              field_preview_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const formatText = text => {
    if (text.length < 120) {
      return text
    } else {
      return text.substr(0, 120) + "..."
    }
  }

  const blogPosts = [...data.allNodeBlog.edges].reverse()

  return (
    <Container>
      {blogPosts.map((post, i) => {
        if (i < 6) {
          const imageAlt = get(["field_preview_image", "alt"], post.node, "")
          const image = get(
            ["relationships", "field_preview_image", "localFile", "publicURL"],
            post.node,
            ""
          )
          const slug =
            (process.env.BASE === "/" ? "" : process.env.BASE) +
            get(["field_url"], post.node, "")
          if (
            slug !== "null" &&
            i < 3 &&
            slug !== "null" &&
            slug !== "/wytesnull"
          ) {
            return (
              <Link key={i} href={`${slug}`}>
                <ArticleRightCard>
                  <ImgContainer>
                    <img src={image} alt={imageAlt} />
                  </ImgContainer>
                  <p>{formatText(post.node.field_preview_text)}</p>
                </ArticleRightCard>
              </Link>
            )
          }
        }
      })}
    </Container>
  )
}

export default BlogSidebar

const Container = styled.div`
  width: 100%;
  @media (max-width: 1023px) and (min-width: 767px) {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 25px;
  }
  @media (max-width: 1023px) {
    width: auto;
    margin: 0 auto 5rem;
  }
  @media (max-width: 767px) and (min-width: 600px) {
    width: 80%;
  }
  @media (max-width: 767px) {
    padding: 0 25px;
  }
`

const Link = styled.a`
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${color.text.link};
  }

  @media (max-width: 1023px) and (min-width: 767px) {
    width: 48%;
  }
`

const ArticleRightCard = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3rem;

  & p {
    width: 175px;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #666666;
    margin-left: 25px;

    &:hover,
    &:active {
      color: ${color.text.link};
    }

    @media (max-width: 1023px) {
      margin-left: 0;
      width: 100%;
      height: 100%;
    }

    @media (min-width: 767px) and (max-width: 1023px) {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1023px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }
`

const ImgContainer = styled.div`
  width: 170px;
  height: 210px;

  & img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  @media (max-width: 1023px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
`
