import React from "react"
import { Caption } from "./BlogImage"
import styled from "styled-components"
const BlogVideo = ({ node }) => {
  const { caption, youtubeLink } = node

  const getIframeLink = () => {
    const link = youtubeLink.split("v=")
    return "https://www.youtube.com/embed/" + link[1]
  }

  return (
    <Container>
      <IframeContainer>
        <iframe
          width="100%"
          height="100%"
          src={getIframeLink()}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </IframeContainer>
      {caption && (
        <Caption>
          <span>Video: </span>
          {caption}
        </Caption>
      )}
    </Container>
  )
}

export default BlogVideo

const Container = styled.div`
  margin-top: 5rem;
`

const IframeContainer = styled.div`
  width: 100%;
  height: 367px;
  position: relative;
  overflow: hidden;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
  }

  @media (max-width: 1023px) {
    height: 215px;
    min-height: 215px;
    border-radius: 4px;
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    height: 300px;
    min-height: 215px;
  }
`

export const fragment = graphql`
  fragment BlogYoutube on paragraph__youtube_video_with_caption {
    id
    caption: field_caption
    youtubeLink: field_youtube_link
  }
`
