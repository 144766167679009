import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { get } from "../../util/functions"
import Img from "gatsby-image"

const BlogImage = ({ node }) => {
  const {
    field_image_desktop,
    field_image_mobile,
    relationships,
    caption,
  } = node
  const imageAlt = field_image_desktop.alt
  const imageAltMob = field_image_mobile && field_image_mobile.alt

  const fluid =
    relationships.field_image_desktop.localFile.childImageSharp.fluid
  const fluidMobile =
    relationships.field_image_mobile.localFile.childImageSharp.fluid

  const sources = [
    {
      ...fluid,
      media: `(min-width: 767px)`,
    },
    {
      ...fluidMobile,
      media: `(max-width: 767px)`,
    },
  ]

  return (
    <Container>
      {fluid && fluidMobile && (
        <Img fluid={sources} alt={imageAlt} placeholderStyle={{ opacity: 0 }} />
      )}

      {caption && (
        <Caption>
          <span>Image: </span>
          {caption}
        </Caption>
      )}
    </Container>
  )
}

export default BlogImage

const Container = styled.div`
  width: 100%;
  max-width: 914px;
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;
  }
`

export const Caption = styled.figcaption`
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  font-style: normal;
  margin-top: 10px;
  & span {
    font-weight: bold;
  }
`

export const fragment = graphql`
  fragment BlogImage on paragraph__image_with_caption {
    id
    caption: field_caption
    field_image_desktop {
      alt
    }
    field_image_mobile {
      alt
    }
    relationships {
      field_image_desktop {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 670) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      field_image_mobile {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 670) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
