import React, { useEffect } from "react"
import { get } from "../../util/functions"
import play from "../../images/Blog/play-icon-article.svg"
import styled from "styled-components"
import { Caption } from "./BlogImage"

const BlogVideo = ({ node }) => {
  const { caption, relationships } = node
  const source = get(
    ["field_video", "localFile", "publicURL"],
    relationships,
    ""
  )

  const poster = get(
    ["field_image_desktop", "localFile", "publicURL"],
    relationships,
    ""
  )
  const customClass = "video" + parseInt(Math.random() * 1000)

  useEffect(() => {
    //Video
    if (typeof document !== "undefined") {
      const playBtn = document.querySelector("#play-btn")
      const video = document.querySelector("." + customClass)
      if (video) {
        video.onpause = function() {
          playBtn.style.display = "block"
          video.controls = false
        }
        video.onseeking = function() {
          playBtn.style.display = "none"
          video.play()
          video.controls = true
        }
      }
    }
  }, [])
  const playVideo = () => {
    if (typeof document !== "undefined") {
      const playBtn = document.querySelector("#play-btn")
      const video = document.querySelector("." + customClass)
      playBtn.style.display = "none"
      video.play()
      video.controls = true
    }
  }
  return (
    <Container>
      <VideoContainer className="video-container">
        <Video className={customClass} poster={poster}>
          <source src={source} type="video/mp4" />
        </Video>
        <img src={play} onClick={playVideo} alt="play" id="play-btn" />
      </VideoContainer>
      <Caption>
        <span>Video: </span>
        {caption}
      </Caption>
    </Container>
  )
}

export default BlogVideo

const Container = styled.div``

const Video = styled.video`
  width: 100%;
  height: 100%;
  top: 0;
`

const VideoContainer = styled.div`
  position: relative;
  height: 368px;
  background-color: black;
  & img {
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    display: block;
  }
  @media (max-width: 1023px) {
    height: 215px;
    min-height: 215px;

    & img {
      height: 66px;
      width: 66px;
    }
  }
  @media (min-width: 600px) and (max-width: 1023px) {
    height: 300px;
    min-height: 215px;
    background: black;
  }
`
export const fragment = graphql`
  fragment BlogVideo on paragraph__video_with_caption {
    id
    caption: field_caption
    relationships {
      field_video {
        localFile {
          publicURL
        }
      }
      field_image_desktop {
        localFile {
          publicURL
        }
      }
    }
  }
`
