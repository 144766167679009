import React from "react"
import BlogImage from "../components/Blog/BlogImage"
import BlogImageBanner from "../components/Blog/BlogImageBanner"
import BlogYoutube from "../components/Blog/BlogYoutube"
import BlogVideo from "../components/Blog/BlogVideo"
import TextContent from "../components/Blog/TextContent"
import BlogQuote from "../components/Blog/BlogQuote"

const components = {
  paragraph__blog_text: TextContent,
  paragraph__image_with_caption: BlogImage,
  paragraph__blog_image_banner: BlogImageBanner,
  paragraph__youtube_video_with_caption: BlogYoutube,
  paragraph__video_with_caption: BlogVideo,
  paragraph__quote: BlogQuote,
}

export const getParagraph = node => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type]
    return <ParagraphComponent key={node.id + Math.random()} node={node} />
  }
  return <p key={node.id}>Unknown type {node.__typename}</p>
}
