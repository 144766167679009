import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import color from "../../style/color"

const TextContent = ({ node }) => {
  const { field_blog_text } = node
  const content = field_blog_text.value
  return <Content dangerouslySetInnerHTML={{ __html: content }} />
}

export default TextContent

export const fragment = graphql`
  fragment BlogTextContent on paragraph__blog_text {
    id
    field_blog_text {
      value
    }
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 914px;
  margin-top: 5rem;

  @media (max-width: 1023px) {
    width: 100%;
  }
  & p,
  & li {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: ${color.text.primary};
    text-align: inherit;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 15px;
    }
  }
  & ul {
    // padding-left: 10px;
    @media (max-width: 1023px) {
      padding-left: 0px;
    }
  }
  & h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: inherit;
    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }
  & h3 {
    font-size: 24px;
    line-height: 34px;
    color: ${color.text.primary};
    text-align: inherit;
    margin-bottom: 17px;
    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 4px;
    }
  }
  a {
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
    color: ${color.brand.blueLight};
  }
  a:hover,
  a:active {
    text-decoration: underline;
  }
  & ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-left: -20px;
  }
  & ol li {
    counter-increment: my-awesome-counter;
    font-size: 16px;
    line-height: 26px;
    color: ${color.text.secondary};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  & ol li:not(:last-of-type) {
    margin-bottom: 10px;
  }
  & ol li::before {
    content: counter(my-awesome-counter) ". ";
    color: ${color.brand.blueLight};
    font-weight: bold;
    line-height: 1.63;
    margin-right: 10px;
    margin-left: 25px;
    @media (max-width: 1023px) {
    }
  }
`
