import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import color from "../../style/color"

const BlogQuote = ({ node }) => {
  const { sectionHeading } = node
  return (
    <Container>
      <h4>{sectionHeading}</h4>
    </Container>
  )
}

export default BlogQuote

export const fragment = graphql`
  fragment BlogQuote on paragraph__quote {
    id
    sectionHeading: field_section_heading
  }
`
const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 5rem auto 0;

  & h4 {
    font-family: Raleway;
    font-weight: 400;
    font-style: italic;
    width: 90%;
    margin: 0 auto;
    color: ${color.brand.blueLight};
  }
`
