import React from "react"
import styled from "styled-components"
import { formatLink } from "../../util/functions"
import { Breadcrumb, Link } from "../../shared/Breadcrumb/Breadcrumb"

const BlogHeader = ({ imageMobile, imageDesktop, breadcrumb }) => {
  return (
    <>
      <MainContainer
        imageMobile={imageMobile}
        imageDesktop={imageDesktop}
      ></MainContainer>
      {breadcrumb && (
        <Breadcrumb blog>
          {breadcrumb.map((item, key) => {
            return (
              <React.Fragment key={key}>
                <Link
                  href={
                    key !== breadcrumb.length - 1 ? formatLink(item.uri) : null
                  }
                >
                  {item.title}
                </Link>
                <span>|</span>{" "}
              </React.Fragment>
            )
          })}
        </Breadcrumb>
      )}
    </>
  )
}

const MainContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  background-image: url(${props => props.imageDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0;
  display: flex;
  align-items: center;
  min-height: 400px;
  @media (max-width: 1200px) {
    background-position: center;
  }
  @media (max-width: 1023px) {
    padding: 200px 25px 30px;
    align-items: flex-end;
  }
  @media (max-width: 767px) {
    background-image: url(${props => props.imageMobile});
    min-height: 350px;
  }
`

export default BlogHeader
